.MuiInputAdornment-positionEnd{
  display: none !important;
}
.App{
  text-align:center
}
@media only screen and (max-width:600px){
  .fillScreen{
    min-height:-webkit-fill-available;
    height: 100vh;
  }
}
@media only screen and (min-width:600px){
  .fillScreen{
    min-height:-webkit-fill-available;
    height: 100vh;
  }
}
@media only screen and (min-width:768px){
  .fillScreen{
    height:1080px
  }
}
@media only screen and (min-width:992px){
  .fillScreen{
    height:1080px
  }
}
@media only screen and (min-width:1200px){
  .fillScreen{
    height:1080px
  }
}
.fillScreen{
  box-shadow:20px 3px 20px 0
}
.App-logo{
  height:40vmin;
  pointer-events:none
}

.App-header{
  background-color:#282c34;
  min-height:100vh;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  -webkit-align-items:center;
  align-items:center;
  -webkit-justify-content:center;
  justify-content:center;
  font-size:calc(10px + 2vmin);
  color:#fff
}
.App-link{
  color:#61dafb
}
